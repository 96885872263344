export const fields = {
  airport1Active: {
    label: 'Airport1 Active',
    value: false,
  },
  airport2Active: {
    label: 'Airport2 Active',
    value: false,
  },
  airport3Active: {
    label: 'Airport3 Active',
    value: false,
  },
  airport4Active: {
    label: 'Airport4 Active',
    value: false,
  },
  airportFlightpakActive: {
    label: 'Airport Flightpak Active',
    value: false,
  },
  runway1Active: {
    label: 'Runway1 Active',
    value: false,
  },
  runway2Active: {
    label: 'Runway2 Active',
    value: false,
  },
  runway3Active: {
    label: 'Runway3 Active',
    value: false,
  },
  runway4Active: {
    label: 'Runway4 Active',
    value: false,
  },
  fboActive: {
    label: 'Fbo Active',
    value: false,
  },
  fboServicesActive: {
    label: 'Fbo Services Active',
    value: false,
  },
  hotelActive: {
    label: 'Hotel Active',
    value: false,
  },
  attendenceActive: {
    label: 'Attendence Active',
    value: false,
  },
  remarksActive: {
    label: 'Remarks Active',
    value: false,
  },
  cateringActive: {
    label: 'Catering Active',
    value: false,
  },
  transportationActive: {
    label: 'Transportation Active',
    value: false,
  },
  securityActive: {
    label: 'Security Active',
    value: false,
  },
  airport1Inactive: {
    label: 'Airport1 Inactive',
    value: false,
  },
  airport2Inactive: {
    label: 'Airport2 Inactive',
    value: false,
  },
  airport3Inactive: {
    label: 'Airport3 Inactive',
    value: false,
  },
  airport4Inactive: {
    label: 'Airport4 Inactive',
    value: false,
  },
  airportFlightpakInactive: {
    label: 'Airport Flightpak Inactive',
    value: false,
  },
  runway1Inactive: {
    label: 'Runway1 Inactive',
    value: false,
  },
  runway2Inactive: {
    label: 'Runway2 Inactive',
    value: false,
  },
  runway3Inactive: {
    label: 'Runway3 Inactive',
    value: false,
  },
  runway4Inactive: {
    label: 'Runway4 Inactive',
    value: false,
  },
  fboInactive: {
    label: 'Fbo Inactive',
    value: false,
  },
  fboServicesInactive: {
    label: 'Fbo Services Inactive',
    value: false,
  },
  hotelInactive: {
    label: 'Hotel Inactive',
    value: false,
  },
  attendenceInactive: {
    label: 'Attendence Inactive',
    value: false,
  },
  remarksInactive: {
    label: 'Remarks Inactive',
    value: false,
  },
  cateringInactive: {
    label: 'Catering Inactive',
    value: false,
  },
  transportationInactive: {
    label: 'Transportation Inactive',
    value: false,
  },
  securityInactive: {
    label: 'Security Inactive',
    value: false,
  },
};
  