export const apiUrls = {
  invalidateCacheData: '/wings/api/cache/invalidate',
  cacheSettings: '/wings/api/GeneralSetting/caching',
  resetSetting: '/wings/api/GeneralSettings/Reset',
  updateSetting: '/wings/api/GeneralSetting',
  mobileReleases: '/wings/api/mobile/releases',
  reportSummary: '/wings/api/cache/attribute/report',
  fuels: '/v2/usermappings',
  uvgoBanners: '/wings/api/notifications',
  uvgoBannerType: '/wings/api/notificationtypes',
  uvgoBannerServices: '/wings/api/notificationservices',
  scottIpc: '/api/ScottIPC/scottipcuser',
  cappsPersonCustomer: '/api/ScottIPC/cappspersoncustomer',
  personId: '/api/ScottIPC/personid',
  settings: '/wings/api/settings',
  exportUserSetting: '/wings/api/settings/export',
  importUserSetting: '/wings/api/settings/import',
  jobType: 'api/recurring/job/types',
  optionKey: 'api/settings/options/keys',
  jobOption: 'api/settings/options',
  featureNotes: 'api/v1/featurenotes',
  featureNoteById: (id: string) => `/api/v1/featurenotes/${id}`,
  syncHistory: '/api/v1/datasync/history',
  triggerCachedCustomerNumber: (customerNumber: string) =>
    `/api/v1/datasync/ReloadCache/customerNumber?customerNumber=${customerNumber}`,
  triggerCachedTripNumber: (tripNumber: number) => `/api/v1/datasync/ReloadCache/tripNumber?tripNumber=${tripNumber}`,
  triggerCachedTripId: (tripId: number) => `/api/v1/datasync/ReloadCache/tripId?tripId=${tripId}`,
  triggerCacheReload: '/api/v1/datasync/jobs/cache/initialize',
  triggerSyncInformation: (tripNumber: number) => `/api/v1/datasync/sync/tripstatus?tripNumber=${tripNumber}`,
  triggerLoadHistoryJob: '/api/v1/datasync/jobs/load/history',
  clearCacheTrips: (customerNumber: string) => `/api/v1/datasync/CleanupDIYTrips?customerNumber=${customerNumber}`,
  triggerArchiveLoadTripsJob: '/api/v1/datasync/jobs/sync/archivedtrips',
  clearAirportsCache: '/wings/api/cache',
  triggerCompletedTripsReload: '/api/v1/datasync/jobs/reload/complete/trips',
  triggerAPGRegistriesJob: '/wings/api/jobs/triggers/apg/registries',
  triggerSyncTrip: (tripNumber: number, username: string) =>
    `/api/v1/datasync/jobs/trips/batchsync?tripNumber=${tripNumber}&username=${username}`,
  refreshUserTripsByUsername: (username: string) => `/api/v1/datasync/refresh/usertrips?username=${username}`,
  retailData: '/wings/api/v1/retailData/logs',
  syncSettings: (value: string) => `/wings/api/GeneralSetting/${value}/all`,
  activeUsers: '/user/v1/cache/users',
};
