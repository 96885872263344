import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) => ({
  headerContainer: {
    display: 'flex',
    alignItems: 'center',
    backgroundColor: theme.palette.background.paper,
    padding: 15,
    marginBottom: 5,
    justifyContent: 'space-between',
  },
  icon: {
    width: 30,
    marginRight: 10,
    fontSize: 30,
    height: '1em',
    fill: '#6e7277',
  },
  heading: {
    fontSize: 22,
  },
  mainroot: {
    display: 'flex',
    height: '100%',
    width: '100%',
  },
  mainContent: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  subSection: {
    display: 'flex',
    alignItems: 'center',
  },
  completed: {
    width: '120px',
    borderRadius: '30px',
    justifyContent: 'center',
    background: '#E8F2DD',
    height: '30px',
    marginTop: '9px',
    color: '#65A61B',
    display: 'flex',
    alignItems: 'center',
    fontWeight: 600,
  },
  enqueued: {
    width: '120px',
    borderRadius: '30px',
    justifyContent: 'center',
    background: '#F8E7DE',
    height: '30px',
    marginTop: '9px',
    color: '#D16124',
    display: 'flex',
    alignItems: 'center',
    fontWeight: 600,
  },
  processing: {
    width: '120px',
    borderRadius: '30px',
    justifyContent: 'center',
    background: '#DDEBF8',
    height: '30px',
    marginTop: '9px',
    color: '#1976D2',
    display: 'flex',
    alignItems: 'center',
    fontWeight: 600,
  },
  failed: {
    width: '120px',
    borderRadius: '30px',
    justifyContent: 'center',
    background: '#FBDDE1',
    height: '30px',
    marginTop: '9px',
    color: '#E11D37',
    display: 'flex',
    alignItems: 'center',
    fontWeight: 600,
  },
}));