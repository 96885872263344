import { makeStyles } from '@material-ui/core/styles';

export const styles = makeStyles(() => ({
  modalRoot: {
    flex: 1,
  },
  paperSize: {
    width: 1150,
    height: '80%',
    margin: 'auto',
  },
  headerWrapper: {
    margin: 0,
    wordBreak: 'break-all',
  },
}));
